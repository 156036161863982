<template>
  <div>
    <base-dialog
        :title="editTitle"
        :visible.sync="dialogVisible"
        width="1200px"
        @cancel="cancel"
        @confirm="confirm"
    >
      <el-row style="margin-bottom:10px">
        <el-col :span="5">{{ $t('templateManage.productType') }}</el-col>
        <el-col :span="10">
          <el-select size="mini" v-model="versionType">
            <el-option v-for="item in versionTypes" :key="item.value" :value="item.value" :label="item.label"/>
          </el-select>
        </el-col>
      </el-row>
      <!-- 弹出表格 -->
      <table-list
          ref="tableList"
          :title="'1111'"
          :url="'/' + $util.getLocalStorage('apiprefix') + $api.templateFindList"
          :defaultParams="dialogDefaultParams"
          :columns="dialogColumns"
          :formData="dialogFormData"
          :config="dialogConfig"
          v-model="dialogTableRow"
          @search="dialogSearch"
          :multiple="true"
      >
      </table-list>
    </base-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapGetters([
        'company'
    ])
  },
  data() {
    return {
      // 弹出表格
      showDialogTableList: false,
      dialogVisible: true,
      editTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {
        companyUid: this.$store.getters.company.uid,
      },
      dialogColumns: [
        // lang:模板名称
        {
          label: this.$t('templateManage.stepName'),
          prop: 'stepName',
        },
        // lang:模板类型
        {
          label: this.$t('templateManage.templateType'),
          prop: 'templateType',
        },
      ],
      dialogFormData: {},
      dialogConfig: [],
      dialogTableRow: [],
      versionType: '',
      versionTypes: [],
    }
  },
  watch: {
    async versionType(val) {
      this.$nextTick(async () => {
        this.dialogTableRow = await this.findCompanyAssignedTemplate(val)
        this.$refs.tableList.setDefaultValue()
      })
    }
  },
  mounted() {
    this.loadProductVersionTypes()
  },
  methods: {
    async findCompanyAssignedTemplate(productVersion) {
      return await this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findCompanyAssignedTemplate,
        headers: {
          product_version: productVersion
        },
        data: {
          companyUid: this.tableRow.uid
        },
      })
    },
    loadProductVersionTypes() {
      this.$ajax({
        url: this.$api.findProductVersionType,
      }).then((data) => {
          this.$ajax({
            url: this.$api.findCompanyProductVersion,
            data: {
              companyUid: this.company.uid
            }
          }).then(res => {
            if(res.length > 0) {
              res.forEach(item => {
                this.versionTypes.push({value:item.versionType, label: this.$dict.workflowProductVersion().filter(ite => ite.value === item.versionType)[0].label})
              })
            } else {
              data.forEach(item => {
                this.versionTypes.push({value:item.value, label: this.$dict.workflowProductVersion().filter(ite => ite.value === item.value)[0].label})
              })
            }
          })
      })
    },
    // 确认表格弹框
    confirm() {
      console.log('this.dialogTableRow', this.dialogTableRow)
      if(! this.versionType) {
        return this.$message.error(this.$t('templateManage.selectProductType'))
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.assignTemplate,
        data: {
          companyUid: this.tableRow.uid,
          templateStepList: this.dialogTableRow,
          versionType: this.versionType,
        },
      }).then(res => {
        console.log('dialogConfirm', res)
        this.$emit('closePage', 'cancel')
      })
    },
    // 关闭表格弹框
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.dialogTableList.searchList(dialogFormData)
    },
  },
}
</script>
